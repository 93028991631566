import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { filter } from "lodash";

import { customerValidationSchema } from "../../utils/Validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Collapse,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
//Import Breadcrumb
import { products } from "../../common/data/ecommerce";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Breadcrumb from "../Common/Breadcrumb";
import dayjs from "dayjs";
import {
  customerData,
  EditCustomer,
  UpdateCustomer,
  DeleteCustomer,
  AddCustomer,
  customersSelector,
  clearData,
  clearCustUpdateLoading,
  clearCustAddLoading,
  clearCustLoadingDatas,
  clearCustDeleteLoading,
  clearErrormsg,
} from "../../store/reducer/CustomerReducer";
import { API_STATUS, paymentType } from "../../utils/constants";
import { assestURL } from "../../services/config";
import {
  countryList,
  currencyList,
  usersSelector,
} from "../../store/reducer/UserReducer";
import { handleTranslate, handleTranslateNo } from "../../utils/translate";

const CustomerList = () => {
  const {
    customerDatas,
    customerCount,
    loading,
    addloading,
    deleteloading,
    updateloading,
    customer_details,
    errorMessage,
  } = useSelector(customersSelector);
  console.log(customer_details, "customer_details");
  const dispatch = useDispatch();
  let [sortBy, setSortBy] = useState("customer_name");
  let [activesortBy, setActiveSortBy] = useState("customer_name");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  let [searchQuery, setSearchQuery] = useState("");
  const [state, setState] = useState({ modal_fullscreen: false });
  const [edit_state, setEditState] = useState({ edit_modal_fullscreen: false });
  const [showAdd, setshowAdd] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [updateID, setUpdateID] = useState("");
  // const [formFields, setFormFields] = useState(categoryFields);
  const statusDropdown = [
    { name: "Active", id: 1 },
    { name: "In-Active", id: 0 },
  ];

  const MySwal = withReactContent(Swal);
  const [accord, setAccord] = useState({
    col1: false,
    col2: false,
    col3: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(customerValidationSchema),
  });
  const { countries, currencies, selectScreenData } =
    useSelector(usersSelector);
  console.log(countries, "countries");
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    control: control2,
    reset: reset2,
    setValue: setValue2,
  } = useForm({
    resolver: yupResolver(customerValidationSchema),
  });
  console.log(errors, "456yu7i");
  const payment = [
    { id: 1, name: "Cash" },
    { id: 2, name: "Credit" },
  ];
  const _ = require("lodash");
  const SelectedCountry = (countrId) => {
    return _.filter(countries, ["id", countrId]);
  };
  const SelectedCurrency = (currencyId) => {
    return _.filter(currencies, ["id", currencyId]);
  };

  const SelectedPayment = (paymentId) => {
    return _.filter(paymentType, ["id", paymentId]);
  };

  const [selectedCountryVal, setCountry] = useState([]);
  const [selectedCurrencyVal, setCurrency] = useState([]);
  const [selectedPaymentVal, setPayment] = useState([]);
  console.log(selectedCountryVal, selectedCurrencyVal, 7890);

  const handleChange = (event) => {
    setCountry(event);
    setCurrency(currencies?.filter((curr) => curr?.id == event[0]?.id));
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event);
    setCountry(
      countries?.filter((country) => country?.id == event[0]?.country_id)
    );
  };
  const handlePayChange = (event) => {
    setPayment(event);
  };
  useEffect(() => {
    if (customerDetails) {
      setCountry(SelectedCountry(183));
      setCurrency(SelectedCurrency("123"));
      setCountry(SelectedCountry(customer_details?.country_name));
      setCurrency(SelectedCurrency(customer_details?.currency_type));
      setPayment(SelectedPayment(customer_details?.payment_method));
    }
  }, [customer_details]);

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    customerDispatch();
  };
  const handleCountry = (event) => {
    setCountry(event);
    console.log(event, "r5tyg");
    setCurrency(currencies?.filter((curr) => curr?.id == event[0]?.id));
  };
  const tog_fullscreen = () => {
    setState((prevState) => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }));

    removeBodyCss();
  };

  const edit_tog_fullscreen = (row) => {
    setEditState((prevState) => ({
      edit_modal_fullscreen: !prevState.edit_modal_fullscreen,
    }));
    if (row.id !== undefined) {
      const custID = row.id;
      dispatch(EditCustomer({ custID }));
      removeBodyCss();
    }
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const handleInput = (e, dest, type) => {
    const { value } = e.target;
    customerDetails[dest] = type !== "" ? "" : value;
    setCustomerDetails({
      ...customerDetails,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    customerDispatch();
  };
  const searchData = (e) => {
    searchQuery = e.target.value;
    setSearchQuery(e.target.value);
    setPage(0);
    customerDispatch();
  };

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
    customerDispatch();
  };
  const [customerDetails, setCustomerDetails] = useState({
    customer_name: "",
    customer_name_arabic: "",
    contact_person: "",
    contact_number: "",
    contact_email: "",
    country_name: null,
    currency_type: "",
    payment_method: "",
    credit_limit: "",
    duedays_limit: "",
    invoice_due_days: "",
    vat_no: "",
    address: "",
    zip_code: null,
    city: "",
    fax: "",
    ar_customer_name: "",
    ar_contact_person: "",
    ar_address: "",
    ar_zip_code: "",
    ar_city: "",
    ar_vat_no: "",
    iban_no: "",
    cost_center_name: "",
    cost_center_ph: "",
  });
  const [userActions, setUserActions] = useState({});
  useEffect(() => {
    customerDispatch();
  }, []);

  const customerDispatch = () => {
    dispatch(
      customerData({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        sortby: sortBy,
        order: order,
      })
    );
    const query = "";
    dispatch(countryList({ query }));
    const c_query = "";
    dispatch(currencyList({ query: c_query }));
  };
  useEffect(() => {
    if (customer_details) {
      reset2(customer_details);
    }
  }, [customer_details]);
  useEffect(() => {
    if (addloading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Customer Added Successfully!");
      customerDispatch();
      dispatch(clearCustLoadingDatas());
      reset();
      setState((prevState) => ({
        modal_fullscreen: !prevState.modal_fullscreen,
      }));
    }
    if (updateloading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Customer Updated Successfully!");
      setEditState((prevState) => ({
        edit_modal_fullscreen: !prevState.edit_modal_fullscreen,
      }));
      customerDispatch();
      dispatch(clearCustLoadingDatas());
      reset2();
    }

    if (deleteloading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Customer Deleted Successfully!");
      customerDispatch();
      dispatch(clearCustLoadingDatas());
    }
    if (errorMessage) {
      toastr.clear();
      if (errorMessage.message) toastr.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toastr.error(data);
      }
      dispatch(clearErrormsg());
    }
  }, [addloading, updateloading, errorMessage, deleteloading]);

  const t_col1 = () => {
    setAccord({ col1: !accord.col1, col2: false, col3: false });
  };
  const t_col2 = () => {
    setAccord({ col1: false, col2: !accord.col2, col3: false });
  };
  const t_col3 = () => {
    setAccord({ col1: false, col2: false, col3: !accord.col3 });
  };
  const onSubmitHandler = (formData) => {
    // delete formData.ar_building;
    delete formData.ar_cost_center_name;
    delete formData.ar_cost_center_ph;

    formData.country_name = selectedCountryVal[0]?.id ?? 183;
    formData.currency_type = selectedCurrencyVal[0]?.id ?? 183;
    formData.payment_method = selectedPaymentVal[0]?.id;
    dispatch(AddCustomer({ formData }));
    console.log(formData, "uihjsfds");
    // reset();
  };
  const onEditSubmitHandler = (formData) => {
    const updateID = customer_details.id;
    formData.country_name = selectedCountryVal[0]?.id;
    formData.currency_type = selectedCurrencyVal[0]?.id;
    formData.payment_method = selectedPaymentVal[0]?.id;
    delete formData.ar_cost_center_name;
    delete formData.ar_cost_center_ph;
    delete formData.updated_at;
    delete formData.created_at;
    delete formData.created_by;
    delete formData.updated_by;
    delete formData.trash;
    delete formData.status;
    console.log(formData, "FormData");
    dispatch(UpdateCustomer({ formData, updateID }));
    reset2();
  };

  const Translate = async (value, field) => {
    const translated_data = await handleTranslate(value);
    const values = getValues();
    setValue("ar_" + field, translated_data);
    setValue2("ar_" + field, translated_data);
    // let
    // if()
    console.log(getValues());
  };
  const TranslateNumber = async (value, field) => {
    console.log(value, field);
    // if (field == "tax_registration_no") field = "tax_registeration_no";
    const translated_data = await handleTranslateNo(value);
    console.log(translated_data, "translated");
    setValue("ar_" + field, translated_data);
    setValue2("ar_" + field, translated_data);
  };

  const deleteCustomer = (row) => {
    //setshowAdd(true);
    //setUpdateID(row.id);
    const deleteID = row.id;
    MySwal.fire({
      title: "Are You Sure to Delete the Customer?",
      icon: "warning",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(DeleteCustomer({ deleteID }));
      }
    });
  };
  useEffect(() => {
    if (selectScreenData && selectScreenData.length > 0) {
      const value = selectScreenData[0]?.actions;
      setUserActions(value || {});
    }
  }, [selectScreenData]);
  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const breadcrumbItems = [
    { title: "Tables", link: "/" },
    { title: "Data Tables", link: "#" },
  ];

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between">
            <h2>Customers</h2>
            {userActions["Customer-add"] && (

            <button
              type="button"
              className="waves-effect waves-light btn btn-primary mb-2"
              onClick={tog_fullscreen}
            >
              Add
            </button>
            )}
          </div>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-8"></div>
                <div className="col-md-4 row">
                  <input
                    type="text"
                    name="search"
                    className="form-control"
                    style={{ float: "right" }}
                    value={searchQuery}
                    onChange={(e) => {
                      searchData(e);
                    }}
                    placeholder="Search Customer"
                  />
                </div>
              </div>
              <div className="table-responsive react-table">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead className="table-light table-nowrap">
                      <TableRow>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "customer_name" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("customer_name");
                            }}
                          >
                            Name
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "contact_person" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("contact_person");
                            }}
                          >
                            Contact Person
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "contact_number" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("contact_number");
                            }}
                          >
                            Mobile
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "contact_email" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("contact_email");
                            }}
                          >
                            Email
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "status" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("status");
                            }}
                          >
                            Status
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "created_at" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("created_at");
                            }}
                          >
                            Created At
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerDatas &&
                        customerDatas.map((row) => (
                          <StyledTableRow>
                            <StyledTableCell align="">
                              {row?.customer_name}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row.contact_person}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row.contact_number}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row.contact_email}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row.status === 1 ? "Active" : "In-active"}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {dayjs(row.created_at).format(
                                "MM-DD-YYYY hh:mm A"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="" className="button-items">
                              {userActions["Customer-edit"] && (

                              <a
                                href="javascript:void(0);"
                                title="Edit"
                                className="btn btn-primary"
                                data-id={row.id}
                                onClick={(e) => {
                                  edit_tog_fullscreen(row);
                                }}
                              >
                                <i className=" ri-ball-pen-line"></i>
                              </a>
                              )}
                              {userActions["Customer-delete"] && (

                              <a
                                href="javascript:void(0);"
                                title="Delete"
                                className="btn btn-danger"
                                onClick={(e) => {
                                  deleteCustomer(row);
                                }}
                              >
                                <i className="ri-delete-bin-line"></i>
                              </a>
                               )} 
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={8}
                          count={customerCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>

              <Col sm={6} md={4} xl={3} className="mt-4">
                <Modal
                  size="xl"
                  isOpen={state.modal_fullscreen}
                  toggle={tog_fullscreen}
                >
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="CustomerAddModal">
                        Customer
                      </h5>
                      <button
                        onClick={() => setState({ modal_fullscreen: false })}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="customer_name"
                              name="customer_name"
                              {...register("customer_name")}
                              onBlur={(e) => {
                                Translate(e.target.value, "customer_name");
                              }}
                            />
                            <label for="customer_name" className="required">
                              Customer Name
                            </label>
                          </div>
                          {errors?.customer_name?.message && (
                            <p className="error">
                              {errors.customer_name.message}
                            </p>
                          )}
                        </Col>
                        <Col md="4" style={{ display: "none" }}>
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="customer_name_arabic"
                              name="customer_name_arabic"
                              {...register("customer_name_arabic")}
                            />
                            <label for="customer_name_arabic">
                              Customer Name (Local)
                            </label>
                          </div>
                          {errors?.customer_name_arabic?.message && (
                            <p className="error">
                              {errors.customer_name_arabic.message}
                            </p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="contact_person"
                              name="contact_person"
                              {...register("contact_person")}
                              onBlur={(e) => {
                                Translate(e.target.value, "contact_person");
                              }}
                            />
                            <label for="contact_person">Contact Person</label>
                          </div>
                          {errors?.contact_person?.message && (
                            <p className="error">
                              {errors.contact_person.message}
                            </p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="contact_number"
                              name="contact_number"
                              {...register("contact_number")}
                            />
                            <label for="contact_number">Contact Number</label>
                          </div>
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="contact_email"
                              name="contact_email"
                              {...register("contact_email")}
                            />
                            <label for="contact_email" className="required">
                              Email
                            </label>
                          </div>
                          {errors?.contact_email?.message && (
                            <p className="error">
                              {errors.contact_email.message}
                            </p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <Controller
                              name="country_name"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  placeholder="Country"
                                  options={countries}
                                  {...field}
                                  disabled={false}
                                  dropdownHandle={true}
                                  searchBy="country_name"
                                  searchable={true}
                                  values={countries?.filter(
                                    (country) => country?.id == 183
                                  )}
                                  labelField={"country_name"}
                                  valueField={"country_code"}
                                  onChange={handleCountry}
                                />
                              )}
                            />
                            {/* <label for="country">Country</label> */}
                          </div>
                          {errors?.country?.message && (
                            <p className="error">{errors.country.message}</p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <Controller
                              name="currency_type"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="js-states select-control"
                                  placeholder="Currency Type"
                                  options={currencies}
                                  values={currencies?.filter(
                                    (country) => country?.id == 183
                                  )}
                                  disabled={false}
                                  dropdownHandle={true}
                                  searchable={true}
                                  searchBy="currency_name"
                                  labelField={"currency_name"}
                                  valueField={"currency_code"}
                                  onChange={handleCurrencyChange}
                                />
                              )}
                            />
                            {/* <label for="currency_type">Currency Type</label> */}
                          </div>
                          {errors?.currency_type?.message && (
                            <p className="error">
                              {errors.currency_type.message}
                            </p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <Controller
                              name="payment_method"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  placeholder="Payment Method"
                                  options={[
                                    { id: 1, name: "Cash" },
                                    { id: 2, name: "Credit" },
                                  ]}
                                  values={[]}
                                  searchBy="name"
                                  disabled={false}
                                  dropdownHandle={true}
                                  searchable={true}
                                  labelField={"name"}
                                  valueField={"id"}
                                  onChange={handlePayChange}
                                />
                              )}
                            />
                            {/* <label for="payment_method">Payment Method</label> */}
                          </div>
                          {errors?.payment_method?.message && (
                            <p className="error">
                              {errors.payment_method.message}
                            </p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="credit_limit"
                              name="credit_limit"
                              {...register("credit_limit")}
                            />
                            <label for="credit_limit">Credit Limit</label>
                          </div>
                          {errors?.credit_limit?.message && (
                            <p className="error">
                              {errors.credit_limit.message}
                            </p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              type="text"
                              placeholder=" "
                              id="duedays_limit"
                              name="duedays_limit"
                              {...register("duedays_limit")}
                            />
                            <label for="duedays_limit">Due Days Limit</label>
                          </div>
                          {errors?.duedays_limit?.message && (
                            <p className="error">
                              {errors.duedays_limit.message}
                            </p>
                          )}
                        </Col>

                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="vat_no"
                              name="vat_no"
                              {...register("vat_no")}
                              onBlur={(e) => {
                                TranslateNumber(e.target.value, "vat_no");
                              }}
                            />
                            <label for="vat_no" className="required">
                              VAT No
                            </label>
                          </div>
                          {errors?.vat_no?.message && (
                            <p className="error">{errors.vat_no.message}</p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              onChange={(e) => handleInput(e, "iban_no", "")}
                              type="text"
                              id="iban_no"
                              name="iban_no"
                              {...register("iban_no")}
                              onBlur={(e) => {
                                TranslateNumber(e.target.value, "iban_no");
                              }}
                            />
                            <label for="iban_no">IBAN</label>
                          </div>
                          {errors?.iban_no?.message && (
                            <p className="error">{errors.iban_no.message}</p>
                          )}
                        </Col>

                        <Col md="4" className="d-none">
                          <div class="input-container d-none">
                            <input
                              placeholder=" "
                              type="text"
                              id="invoice_due_days"
                              name="invoice_due_days"
                              {...register("invoice_due_days")}
                            />
                            <label for="invoice_due_days">
                              Invoice Due Days
                            </label>
                          </div>
                          {errors?.invoice_due_days?.message && (
                            <p className="error">
                              {errors.invoice_due_days.message}
                            </p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div class="input-container ">
                            <input
                              placeholder=" "
                              type="text"
                              id="cr_number"
                              name="invoice_due_days"
                              {...register("cr_number")}
                              onBlur={(e) => {
                                TranslateNumber(e.target.value, "cr_number");
                              }}
                            />
                            <label for="CRN_number" className="required">
                              CR NO
                            </label>
                          </div>
                          {errors?.cr_number?.message && (
                            <p className="error">{errors.cr_number.message}</p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              type="text"
                              placeholder=" "
                              id="building"
                              name="building"
                              {...register("building")}
                              onBlur={(e) => {
                                TranslateNumber(e.target.value, "building");
                              }}
                            />
                            <label for="building" className="required">
                              Building
                            </label>
                          </div>
                          {errors?.building?.message && (
                            <p className="error">{errors.building.message}</p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="address"
                              name="address"
                              {...register("address")}
                              onBlur={(e) => {
                                Translate(e.target.value, "address");
                              }}
                            />
                            <label for="address" className="required">
                              Street
                            </label>
                          </div>
                          {errors?.address?.message && (
                            <p className="error">{errors.address.message}</p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="city"
                              name="city"
                              {...register("city")}
                              onBlur={(e) => {
                                Translate(e.target.value, "city");
                              }}
                            />
                            <label for="street" className="required">
                              City
                            </label>
                          </div>
                          {errors?.city?.message && (
                            <p className="error">{errors.city.message}</p>
                          )}
                        </Col>
                        {/* </Row>
                                <Row> */}
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="city_subdivision"
                              name="city_subdivision"
                              {...register("city_subdivision")}
                              onBlur={(e) => {
                                Translate(e.target.value, "city_subdivision");
                              }}
                            />
                            <label for="city_subdivision" className="required">
                              District
                            </label>
                          </div>
                          {errors?.city_subdivision?.message && (
                            <p className="error">
                              {errors.city_subdivision.message}
                            </p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="plot_identification"
                              name="plot_identification"
                              {...register("plot_identification")}
                              onBlur={(e) => {
                                TranslateNumber(
                                  e.target.value,
                                  "plot_identification"
                                );
                              }}
                            />
                            <label for="fax" className="required">
                              Plot Identification
                            </label>
                          </div>
                          {errors?.plot_identification?.message && (
                            <p className="error">
                              {errors.plot_identification.message}
                            </p>
                          )}
                        </Col>
                        <Row>
                          <Col md="4">
                            <div class="input-container">
                              <input
                                placeholder=" "
                                type="text"
                                id="zip_code"
                                name="zip_code"
                                {...register("zip_code")}
                                onBlur={(e) => {
                                  TranslateNumber(e.target.value, "zip_code");
                                }}
                              />
                              <label for="zip_code" className="required">
                                Zipcode
                              </label>
                            </div>
                            {errors?.zip_code?.message && (
                              <p className="error">{errors.zip_code.message}</p>
                            )}
                          </Col>
                          <Col md="4">
                            <div class="input-container">
                              <input
                                placeholder=" "
                                type="text"
                                id="cost_center_name"
                                name="cost_center_name"
                                {...register("cost_center_name")}
                              />
                              <label for="cost_center_name">
                                Cost Center Name
                              </label>
                            </div>
                            {errors?.cost_center_name?.message && (
                              <p className="error">
                                {errors.cost_center_name.message}
                              </p>
                            )}
                          </Col>
                          <Col md="4">
                            <div class="input-container">
                              <input
                                placeholder=" "
                                type="text"
                                id="cost_center_ph"
                                name="cost_center_ph"
                                {...register("cost_center_ph")}
                              />
                              <label for="cost_center_ph">
                                Cost Center Number
                              </label>
                            </div>
                            {errors?.cost_center_ph?.message && (
                              <p className="error">
                                {errors.cost_center_ph.message}
                              </p>
                            )}
                          </Col>
                        </Row>
                        <div id="accordion">
                          <Card
                            className="mb-1 shadow-none hide"
                            style={{ display: "none" }}
                          >
                            <Link
                              to="#"
                              onClick={t_col1}
                              style={{ cursor: "pointer" }}
                              className="text-dark"
                            >
                              <CardHeader id="headingOne">
                                <h6 className="m-0 font-14">
                                  Contact Details
                                  <i
                                    className={
                                      accord.col1
                                        ? "mdi mdi-minus float-end accor-plus-icon"
                                        : "mdi mdi-plus float-end accor-plus-icon"
                                    }
                                  ></i>
                                </h6>
                              </CardHeader>
                            </Link>
                            <Collapse isOpen={accord.col1}>
                              <CardBody>
                                {/* <Row>
                                  <Col md="6">
                                    <div class="input-container">
                                      <textarea
                                        rows={1}
                                        placeholder=" "
                                        type="text"
                                        id="address"
                                        name="address"
                                        {...register("address")}
                                        onBlur={(e) => {Translate(e.target.value,'address')}}
                                      />
                                      <label for="address">Address</label>
                                    </div>
                                    {errors?.address?.message && (
                                      <p className="error">
                                        {errors.address.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row> */}
                              </CardBody>
                            </Collapse>
                          </Card>
                        </div>
                        <div id="accordion">
                          <Card className="mb-1 shadow-none">
                            <Link
                              to="#"
                              onClick={t_col2}
                              style={{ cursor: "pointer" }}
                              className="text-dark"
                            >
                              <CardHeader id="headingOne">
                                <h6 className="m-0 font-14">
                                  Arabic Details
                                  <i
                                    className={
                                      accord.col2
                                        ? "mdi mdi-minus float-end accor-plus-icon"
                                        : "mdi mdi-plus float-end accor-plus-icon"
                                    }
                                  ></i>
                                </h6>
                              </CardHeader>
                            </Link>
                            <Collapse isOpen={accord.col2}>
                              {/* <CardBody>
                                <Row>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        type="text"
                                        placeholder=" "
                                        id="city"
                                        name="city"
                                        {...register("city")}
                                      />
                                      <label for="city">City</label>
                                    </div>
                                    {errors?.city?.message && (
                                      <p className="error">
                                        {errors.city.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="zip_code"
                                        name="zip_code"
                                        {...register("zip_code")}
                                      />
                                      <label for="zip_code">Zipcode</label>
                                    </div>
                                    {errors?.zip_code?.message && (
                                      <p className="error">
                                        {errors.zip_code.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        {...register("phone")}
                                      />
                                      <label for="phone">Phone</label>
                                    </div>
                                    {errors?.phone?.message && (
                                      <p className="error">
                                        {errors.phone.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="fax"
                                        name="fax"
                                        {...register("fax")}
                                      />
                                      <label for="fax">Fax</label>
                                    </div>
                                    {errors?.fax?.message && (
                                      <p className="error">
                                        {errors.fax.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="web_address"
                                        name="web_address"
                                        {...register("web_address")}
                                      />
                                      <label for="web_address">Website</label>
                                    </div>
                                    {errors?.web_address?.message && (
                                      <p className="error">
                                        {errors.web_address.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <div class="input-container">
                                      <textarea
                                        rows={1}
                                        placeholder=" "
                                        type="text"
                                        id="address"
                                        name="address"
                                        {...register("address")}
                                      />
                                      <label for="address">Address</label>
                                    </div>
                                    {errors?.address?.message && (
                                      <p className="error">
                                        {errors.address.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                              </CardBody> */}
                              <div className="modal-body">
                                <Row>
                                  {/* <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="edit_customer_name"
                              defaultValue={customer_details?.customer_name}
                              {...register2("customer_name")}
                            />
                            <label for="customer_name"
                              className="required">Customer Name</label>
                          </div>
                          {errors?.customer_name?.message && (
                            <p className="error">
                              {errors.customer_name.message}
                            </p>
                          )}
                        </Col> */}
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="edit_customer_name_arabic"
                                        name="customer_name_arabic"
                                        {...register("ar_customer_name")}
                                        style={{ direction: "rtl" }}
                                        defaultValue={
                                          customer_details?.customer_name_arabic
                                        }
                                      />
                                      <label for="customer_name_arabic">
                                        Customer Name (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_customer_name?.message && (
                                      <p className="error">
                                        {errors.ar_customer_name.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="edit_ar_contact_person"
                                        name="ar_contact_person"
                                        {...register("ar_contact_person")}
                                        style={{ direction: "rtl" }}
                                        defaultValue={
                                          customer_details?.ar_contact_person
                                        }
                                      />
                                      <label for="ar_contact_person">
                                        Contact Person (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_contact_person?.message && (
                                      <p className="error">
                                        {errors.ar_contact_person.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="ar_vat_no"
                                        name="ar_vat_no"
                                        {...register("ar_vat_no")}
                                        style={{ direction: "rtl" }}
                                      />
                                      <label
                                        for="ar_vat_no"
                                        className="required"
                                      >
                                        VAT No (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_vat_no?.message && (
                                      <p className="error">
                                        {errors.ar_vat_no.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="ar_iban_no"
                                        name="ar_iban_no"
                                        {...register("ar_iban_no")}
                                        style={{ direction: "rtl" }}
                                      />
                                      <label
                                        for="ar_iban_no"
                                        className="required"
                                      >
                                        IBAN (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_iban_no?.message && (
                                      <p className="error">
                                        {errors.ar_iban_no.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container ">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="ar_cr_number"
                                        name="invoice_due_days"
                                        {...register("ar_cr_number")}
                                        style={{ direction: "rtl" }}
                                      />
                                      <label
                                        for="CRN_number"
                                        className="required"
                                      >
                                        CR NO (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_cr_number?.message && (
                                      <p className="error">
                                        {errors.ar_cr_number.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        type="text"
                                        defaultValue={
                                          customer_details?.ar_building
                                        }
                                        placeholder=" "
                                        id="ar_building"
                                        name="ar_building"
                                        style={{ direction: "rtl" }}
                                        {...register("ar_building")}
                                      />
                                      <label
                                        for="ar_building"
                                        className="required"
                                      >
                                        Building (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_building?.message && (
                                      <p className="error">
                                        {errors.ar_building.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        defaultValue={
                                          customer_details?.ar_address
                                        }
                                        type="text"
                                        id="ar_address"
                                        name="ar_address"
                                        style={{ direction: "rtl" }}
                                        {...register("ar_address")}
                                      />
                                      <label
                                        for="ar_address"
                                        className="required"
                                      >
                                        Street (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_address?.message && (
                                      <p className="error">
                                        {errors.ar_address.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="ar_city"
                                        name="ar_city"
                                        style={{ direction: "rtl" }}
                                        {...register("ar_city")}
                                      />
                                      <label for="street" className="required">
                                        City (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_city?.message && (
                                      <p className="error">
                                        {errors.ar_city.message}
                                      </p>
                                    )}
                                  </Col>
                                  {/* </Row>
                                <Row> */}
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="ar_city_subdivision"
                                        name="ar_city_subdivision"
                                        style={{ direction: "rtl" }}
                                        {...register("ar_city_subdivision")}
                                      />
                                      <label
                                        for="ar_city_subdivision"
                                        className="required"
                                      >
                                        District (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_city_subdivision?.message && (
                                      <p className="error">
                                        {errors.ar_city_subdivision.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="ar_plot_identification"
                                        name="ar_plot_identification"
                                        style={{ direction: "rtl" }}
                                        {...register("ar_plot_identification")}
                                      />
                                      <label for="fax" className="required">
                                        Plot Identification (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_plot_identification
                                      ?.message && (
                                      <p className="error">
                                        {errors.ar_plot_identification.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="ar_zip_code"
                                        name="ar_zip_code"
                                        style={{ direction: "rtl" }}
                                        {...register("ar_zip_code")}
                                      />
                                      <label
                                        for="ar_zip_code"
                                        className="required"
                                      >
                                        Zipcode (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_zip_code?.message && (
                                      <p className="error">
                                        {errors.ar_zip_code.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </Collapse>
                          </Card>
                        </div>
                      </Row>
                    </div>

                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => setState({ modal_fullscreen: false })}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary ">
                        Save changes
                      </button>
                    </div>
                  </form>
                </Modal>
              </Col>
              {/* Edit Customer */}
              <Col sm={6} md={4} xl={3} className="mt-4">
                <Modal
                  size="xl"
                  isOpen={edit_state.edit_modal_fullscreen}
                  toggle={edit_tog_fullscreen}
                >
                  <form onSubmit={handleSubmit2(onEditSubmitHandler)}>
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="CustomerEditModal">
                        Customer Edit
                      </h5>
                      <button
                        onClick={() =>
                          setEditState({ edit_modal_fullscreen: false })
                        }
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="edit_customer_name"
                              defaultValue={customer_details?.customer_name}
                              {...register2("customer_name")}
                              onBlur={(e) => {
                                Translate(e.target.value, "customer_name");
                              }}
                            />
                            <label for="customer_name" className="required">
                              Customer Name
                            </label>
                          </div>
                          {errors?.customer_name?.message && (
                            <p className="error">
                              {errors.customer_name.message}
                            </p>
                          )}
                        </Col>
                        <Col md="4" style={{ display: "none" }}>
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="edit_customer_name_arabic"
                              name="customer_name_arabic"
                              {...register2("customer_name_arabic")}
                              defaultValue={
                                customer_details?.customer_name_arabic
                              }
                            />
                            <label for="customer_name_arabic">
                              Customer Name (Arabic)
                            </label>
                          </div>
                          {errors?.customer_name_arabic?.message && (
                            <p className="error">
                              {errors.customer_name_arabic.message}
                            </p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="edit_contact_person"
                              name="contact_person"
                              {...register2("contact_person")}
                              defaultValue={customer_details?.contact_person}
                              onBlur={(e) => {
                                Translate(e.target.value, "contact_person");
                              }}
                            />
                            <label for="contact_person">Contact Person</label>
                          </div>
                          {errors?.contact_person?.message && (
                            <p className="error">
                              {errors.contact_person.message}
                            </p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="edit_contact_number"
                              name="contact_number"
                              {...register2("contact_number")}
                              defaultValue={customer_details?.contact_number}
                            />
                            <label for="contact_number">Contact Number</label>
                          </div>
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="edit_contact_email"
                              name="contact_email"
                              {...register2("contact_email")}
                              defaultValue={customer_details?.contact_email}
                            />
                            <label for="contact_email">Email</label>
                          </div>
                          {errors?.contact_email?.message && (
                            <p className="error">
                              {errors.contact_email.message}
                            </p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <Controller
                              name="country_name"
                              control={control}
                              // render={({ field,onChange,value }) => (
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  placeholder="Country"
                                  options={countries}
                                  {...field}
                                  disabled={false}
                                  dropdownHandle={true}
                                  values={selectedCountryVal}
                                  searchable={true}
                                  searchBy="country_name"
                                  labelField={"country_name"}
                                  valueField={"country_code"}
                                  // name="country_name"
                                  onChange={handleChange}
                                />
                              )}
                            />

                            {/* <label for="country">Country</label> */}
                            {/* <select className="js-states select-control"
                              placeholder="Country"
                            >
                              {options}
                            </select> */}
                          </div>
                          {errors?.country?.message && (
                            <p className="error">{errors.country.message}</p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <Controller
                              name="currency_type"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="js-states select-control"
                                  placeholder="Currency Type"
                                  options={currencies}
                                  {...field}
                                  disabled={false}
                                  dropdownHandle={true}
                                  values={selectedCurrencyVal}
                                  searchable={true}
                                  searchBy="currency_name"
                                  labelField={"currency_name"}
                                  valueField={"currency_code"}
                                  name="currency_name"
                                  onChange={handleCurrencyChange}
                                />
                              )}
                            />
                            {/* <label for="currency_type">Currency Type</label> */}
                          </div>
                          {errors?.currency_type?.message && (
                            <p className="error">
                              {errors.currency_type.message}
                            </p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <Controller
                              name="payment_method"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className="js-states select-control"
                                  placeholder="Payment Method"
                                  options={paymentType}
                                  {...field}
                                  disabled={false}
                                  dropdownHandle={true}
                                  values={selectedPaymentVal}
                                  searchable={true}
                                  searchBy="mode"
                                  labelField={"mode"}
                                  valueField={"id"}
                                  name="payment_method"
                                  onChange={handlePayChange}
                                />
                              )}
                            />
                            {/* <label for="payment_method">Payment Method</label> */}
                          </div>
                          {errors?.payment_method?.message && (
                            <p className="error">
                              {errors.payment_method.message}
                            </p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="edit_credit_limit"
                              name="credit_limit"
                              {...register2("credit_limit")}
                              defaultValue={customer_details?.credit_limit}
                            />
                            <label for="credit_limit">Credit Limit</label>
                          </div>
                          {errors?.credit_limit?.message && (
                            <p className="error">
                              {errors.credit_limit.message}
                            </p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              type="text"
                              placeholder=" "
                              id="edit_duedays_limit"
                              name="duedays_limit"
                              {...register2("duedays_limit")}
                              defaultValue={customer_details?.duedays_limit}
                            />
                            <label for="duedays_limit">Due Days Limit</label>
                          </div>
                          {errors?.duedays_limit?.message && (
                            <p className="error">
                              {errors.duedays_limit.message}
                            </p>
                          )}
                        </Col>

                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              onChange={(e) => handleInput(e, "vat_no", "")}
                              type="text"
                              id="edit_vat_no"
                              name="vat_no"
                              {...register2("vat_no")}
                              defaultValue={customer_details?.vat_no}
                              onBlur={(e) => {
                                TranslateNumber(e.target.value, "vat_no");
                              }}
                            />
                            <label for="vat_no">VAT No</label>
                          </div>
                          {errors?.vat_no?.message && (
                            <p className="error">{errors.vat_no.message}</p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              onChange={(e) => handleInput(e, "iban_no", "")}
                              type="text"
                              id="iban_no"
                              name="iban_no"
                              {...register2("iban_no")}
                              defaultValue={customer_details?.iban_no}
                              onBlur={(e) => {
                                TranslateNumber(e.target.value, "iban_no");
                              }}
                            />
                            <label for="iban_no">IBAN</label>
                          </div>
                          {errors?.iban_no?.message && (
                            <p className="error">{errors.iban_no.message}</p>
                          )}
                        </Col>
                        <Col md="4" className="d-none">
                          <div class="input-container d-none">
                            <input
                              placeholder=" "
                              type="text"
                              id="edit_invoice_due_days"
                              name="invoice_due_days"
                              {...register2("invoice_due_days")}
                              defaultValue={customer_details?.invoice_due_days}
                            />
                            <label for="invoice_due_days">
                              Invoice Due Days
                            </label>
                          </div>
                          {errors?.invoice_due_days?.message && (
                            <p className="error">
                              {errors.invoice_due_days.message}
                            </p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div class="input-container ">
                            <input
                              placeholder=" "
                              type="text"
                              id="cr_number"
                              name="cr_number"
                              {...register2("cr_number")}
                              defaultValue={customer_details?.cr_number}
                              onBlur={(e) => {
                                TranslateNumber(e.target.value, "cr_number");
                              }}
                            />
                            <label for="cr_number">CR NO</label>
                          </div>
                          {errors?.cr_number?.message && (
                            <p className="error">{errors.cr_number.message}</p>
                          )}
                        </Col>

                        <Col md="4">
                          <div class="input-container">
                            <input
                              type="text"
                              placeholder=" "
                              id="building"
                              name="building"
                              {...register2("building")}
                              onBlur={(e) => {
                                TranslateNumber(e.target.value, "building");
                              }}
                            />
                            <label for="building">Building</label>
                          </div>
                          {errors?.building?.message && (
                            <p className="error">{errors.building.message}</p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="address"
                              name="address"
                              {...register2("address")}
                              defaultValue={customer_details?.address}
                              onBlur={(e) => {
                                Translate(e.target.value, "address");
                              }}
                            />
                            <label for="address">Street Address</label>
                          </div>
                          {errors?.address?.message && (
                            <p className="error">{errors.address.message}</p>
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="city"
                              name="city"
                              {...register2("city")}
                              defaultValue={customer_details?.city}
                              onBlur={(e) => {
                                Translate(e.target.value, "city");
                              }}
                            />
                            <label for="street">City</label>
                          </div>
                          {errors?.city?.message && (
                            <p className="error">{errors.city.message}</p>
                          )}
                        </Col>
                        {/* </Row> */}
                        {/* <Row> */}
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="city_subdivision"
                              name="city_subdivision"
                              {...register2("city_subdivision")}
                              defaultValue={customer_details?.city_subdivision}
                              onBlur={(e) => {
                                Translate(e.target.value, "city_subdivision");
                              }}
                            />
                            <label for="city_subdivision">
                              City-SubDivision
                            </label>
                          </div>
                          {errors?.city_subdivision?.message && (
                            <p className="error">
                              {errors.city_subdivision.message}
                            </p>
                          )}
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="plot_identification"
                              name="plot_identification"
                              {...register2("plot_identification")}
                              defaultValue={
                                customer_details?.plot_identification
                              }
                              onBlur={(e) => {
                                TranslateNumber(
                                  e.target.value,
                                  "plot_identification"
                                );
                              }}
                            />
                            <label for="fax">Plot Identification</label>
                          </div>
                          {errors?.plot_identification?.message && (
                            <p className="error">
                              {errors.plot_identification.message}
                            </p>
                          )}
                        </Col>

                        <Row>
                          <Col md="4">
                            <div class="input-container">
                              <input
                                placeholder=" "
                                type="text"
                                id="zip_code"
                                name="zip_code"
                                {...register2("zip_code")}
                                defaultValue={customer_details?.zip_code}
                                onBlur={(e) => {
                                  TranslateNumber(e.target.value, "zip_code");
                                }}
                              />
                              <label for="zip_code">Zip code</label>
                            </div>
                            {errors?.zip_code?.message && (
                              <p className="error">{errors.zip_code.message}</p>
                            )}
                          </Col>
                          <Col md="4">
                            <div class="input-container">
                              <input
                                placeholder=" "
                                type="text"
                                id="cost_center_name"
                                name="cost_center_name"
                                {...register2("cost_center_name")}
                                onBlur={(e) => {
                                  Translate(e.target.value, "cost_center_name");
                                }}
                              />
                              <label for="cost_center_name">
                                Cost Center Name
                              </label>
                            </div>
                            {errors?.cost_center_name?.message && (
                              <p className="error">
                                {errors.cost_center_name.message}
                              </p>
                            )}
                          </Col>
                          <Col md="4">
                            <div class="input-container">
                              <input
                                placeholder=" "
                                type="text"
                                id="cost_center_ph"
                                name="cost_center_ph"
                                {...register2("cost_center_ph")}
                                onBlur={(e) => {
                                  Translate(e.target.value, "cost_center_ph");
                                }}
                              />
                              <label for="cost_center_ph">
                                Cost Center Number
                              </label>
                            </div>
                            {errors?.cost_center_ph?.message && (
                              <p className="error">
                                {errors.cost_center_ph.message}
                              </p>
                            )}
                          </Col>
                        </Row>

                        <div id="accordion">
                          <Card
                            className="mb-1 shadow-none hide"
                            style={{ display: "none" }}
                          >
                            <Link
                              to="#"
                              onClick={t_col1}
                              style={{ cursor: "pointer" }}
                              className="text-dark"
                            >
                              <CardHeader id="headingOne">
                                <h6 className="m-0 font-14">
                                  Contact Details
                                  <i
                                    className={
                                      accord.col1
                                        ? "mdi mdi-minus float-end accor-plus-icon"
                                        : "mdi mdi-plus float-end accor-plus-icon"
                                    }
                                  ></i>
                                </h6>
                              </CardHeader>
                            </Link>
                            <Collapse isOpen={accord.col1}>
                              <CardBody>
                                {/* <Col md="6">
                                    <div class="input-container">
                                      <textarea
                                        rows={1}
                                        placeholder=" "
                                        type="text"
                                        id="edit_address"
                                        name="address"
                                        {...register2("address")}
                                        defaultValue={customer_details?.address}
                                      />
                                      <label for="address">Address</label>
                                    </div>
                                    {errors?.address?.message && (
                                      <p className="error">
                                        {errors.address.message}
                                      </p>
                                    )}
                                  </Col> */}
                                {/* </Row> */}
                              </CardBody>
                            </Collapse>
                          </Card>
                        </div>
                        <div id="accordion">
                          <Card className="mb-1 shadow-none">
                            <Link
                              to="#"
                              onClick={t_col2}
                              style={{ cursor: "pointer" }}
                              className="text-dark"
                            >
                              <CardHeader id="headingOne">
                                <h6 className="m-0 font-14">
                                  Arabic Details
                                  <i
                                    className={
                                      accord.col2
                                        ? "mdi mdi-minus float-end accor-plus-icon"
                                        : "mdi mdi-plus float-end accor-plus-icon"
                                    }
                                  ></i>
                                </h6>
                              </CardHeader>
                            </Link>
                            <Collapse isOpen={accord.col2}>
                              {/* <CardBody>
                                <Row>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        type="text"
                                        placeholder=" "
                                        id="city"
                                        name="city"
                                        {...register("city")}
                                      />
                                      <label for="city">City</label>
                                    </div>
                                    {errors?.city?.message && (
                                      <p className="error">
                                        {errors.city.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="zip_code"
                                        name="zip_code"
                                        {...register("zip_code")}
                                      />
                                      <label for="zip_code">Zipcode</label>
                                    </div>
                                    {errors?.zip_code?.message && (
                                      <p className="error">
                                        {errors.zip_code.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        {...register("phone")}
                                      />
                                      <label for="phone">Phone</label>
                                    </div>
                                    {errors?.phone?.message && (
                                      <p className="error">
                                        {errors.phone.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="fax"
                                        name="fax"
                                        {...register("fax")}
                                      />
                                      <label for="fax">Fax</label>
                                    </div>
                                    {errors?.fax?.message && (
                                      <p className="error">
                                        {errors.fax.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="web_address"
                                        name="web_address"
                                        {...register("web_address")}
                                      />
                                      <label for="web_address">Website</label>
                                    </div>
                                    {errors?.web_address?.message && (
                                      <p className="error">
                                        {errors.web_address.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <div class="input-container">
                                      <textarea
                                        rows={1}
                                        placeholder=" "
                                        type="text"
                                        id="address"
                                        name="address"
                                        {...register("address")}
                                      />
                                      <label for="address">Address</label>
                                    </div>
                                    {errors?.address?.message && (
                                      <p className="error">
                                        {errors.address.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                              </CardBody> */}
                              <div className="modal-body">
                                <Row>
                                  {/* <Col md="4">
                          <div class="input-container">
                            <input
                              placeholder=" "
                              type="text"
                              id="edit_customer_name"
                              defaultValue={customer_details?.customer_name}
                              {...register2("customer_name")}
                            />
                            <label for="customer_name"
                              className="required">Customer Name</label>
                          </div>
                          {errors?.customer_name?.message && (
                            <p className="error">
                              {errors.customer_name.message}
                            </p>
                          )}
                        </Col> */}
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="edit_customer_name_arabic"
                                        name="customer_name_arabic"
                                        {...register2("ar_customer_name")}
                                        style={{ direction: "rtl" }}
                                        defaultValue={
                                          customer_details?.customer_name_arabic
                                        }
                                      />
                                      <label for="customer_name_arabic">
                                        Customer Name (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_customer_name?.message && (
                                      <p className="error">
                                        {errors.ar_customer_name.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="edit_ar_contact_person"
                                        name="ar_contact_person"
                                        {...register2("ar_contact_person")}
                                        style={{ direction: "rtl" }}
                                        defaultValue={
                                          customer_details?.ar_contact_person
                                        }
                                      />
                                      <label for="ar_contact_person">
                                        Contact Person (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_contact_person?.message && (
                                      <p className="error">
                                        {errors.ar_contact_person.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="ar_vat_no"
                                        name="ar_vat_no"
                                        {...register2("ar_vat_no")}
                                        style={{ direction: "rtl" }}
                                      />
                                      <label
                                        for="ar_vat_no"
                                        className="required"
                                      >
                                        VAT No (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_vat_no?.message && (
                                      <p className="error">
                                        {errors.ar_vat_no.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="ar_iban_no"
                                        name="ar_iban_no"
                                        {...register2("ar_iban_no")}
                                        style={{ direction: "rtl" }}
                                      />
                                      <label
                                        for="ar_iban_no"
                                        className="required"
                                      >
                                        IBAN (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_iban_no?.message && (
                                      <p className="error">
                                        {errors.ar_iban_no.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container ">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="ar_cr_number"
                                        name="invoice_due_days"
                                        defaultValue={
                                          customer_details?.ar_cr_number
                                        }
                                        {...register2("ar_cr_number")}
                                        style={{ direction: "rtl" }}
                                      />
                                      <label
                                        for="CRN_number"
                                        className="required"
                                      >
                                        CR NO (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_cr_number?.message && (
                                      <p className="error">
                                        {errors.ar_cr_number.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        type="text"
                                        defaultValue={
                                          customer_details?.ar_building
                                        }
                                        placeholder=" "
                                        id="ar_building"
                                        name="ar_building"
                                        style={{ direction: "rtl" }}
                                        {...register2("ar_building")}
                                      />
                                      <label
                                        for="ar_building"
                                        className="required"
                                      >
                                        Building (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_building?.message && (
                                      <p className="error">
                                        {errors.ar_building.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>

                                <Row>
                                  {" "}
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        defaultValue={
                                          customer_details?.ar_address
                                        }
                                        type="text"
                                        id="ar_address"
                                        name="ar_address"
                                        style={{ direction: "rtl" }}
                                        {...register2("ar_address")}
                                      />
                                      <label
                                        for="ar_address"
                                        className="required"
                                      >
                                        Street (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_address?.message && (
                                      <p className="error">
                                        {errors.ar_address.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="ar_city"
                                        name="ar_city"
                                        style={{ direction: "rtl" }}
                                        {...register2("ar_city")}
                                      />
                                      <label for="street" className="required">
                                        City (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_city?.message && (
                                      <p className="error">
                                        {errors.ar_city.message}
                                      </p>
                                    )}
                                  </Col>
                                  {/* </Row>
                                <Row> */}
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="ar_city_subdivision"
                                        name="ar_city_subdivision"
                                        style={{ direction: "rtl" }}
                                        {...register2("ar_city_subdivision")}
                                      />
                                      <label
                                        for="ar_city_subdivision"
                                        className="required"
                                      >
                                        District (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_city_subdivision?.message && (
                                      <p className="error">
                                        {errors.ar_city_subdivision.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>

                                <Row>
                                  {" "}
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="ar_plot_identification"
                                        name="ar_plot_identification"
                                        style={{ direction: "rtl" }}
                                        {...register2("ar_plot_identification")}
                                      />
                                      <label for="fax" className="required">
                                        Plot Identification (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_plot_identification
                                      ?.message && (
                                      <p className="error">
                                        {errors.ar_plot_identification.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div class="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="ar_zip_code"
                                        name="ar_zip_code"
                                        style={{ direction: "rtl" }}
                                        {...register2("ar_zip_code")}
                                      />
                                      <label
                                        for="ar_zip_code"
                                        className="required"
                                      >
                                        Zipcode (Arabic)
                                      </label>
                                    </div>
                                    {errors?.ar_zip_code?.message && (
                                      <p className="error">
                                        {errors.ar_zip_code.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </Collapse>
                          </Card>
                        </div>
                      </Row>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() =>
                          setEditState({ edit_modal_fullscreen: false })
                        }
                        className="btn btn-secondary "
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary ">
                        Update changes
                      </button>
                    </div>
                  </form>
                </Modal>
              </Col>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default CustomerList;
