import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { MetisMenu } from "metismenujs";
import {
  clearUserLoadingDatas,
  getUserSelectScreen,
  usersSelector,
} from "../../store/reducer/UserReducer";
import { API_STATUS } from "../../utils/constants";
function Sidebar() {
  const dispatch = useDispatch();
  const { selectScreenData, addloading,updateloading } = useSelector(usersSelector);
  console.log(addloading, "3456sfh-=789");
  console.log(selectScreenData, "3455tyuj");
  const userId = localStorage.getItem("id");
  const roleID = localStorage.getItem("Role");
  let template_settings = JSON.parse(localStorage.getItem("template"));
  const hasMounted = useRef(false);
  console.log(hasMounted.current, "4567890");
  const [userActions, setUserActions] = useState({});
  console.log(userActions, "45678i");
  const initMenu = () => {
    MetisMenu.attach("#side-menu");
    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    console.log(matchingMenuItem, "matchingMenuItem");
    if (matchingMenuItem) {
      //   activateParentDropdown(matchingMenuItem);
    }
  };

  const hasAction = (screenName, actions) => {
    const result = Object.keys(actions).some(
      (action) => userActions[`${screenName}-${action}`]
    );
    console.log(result, "2345678");
    return result;
  };

  const activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    initMenu();
    if (userId) {
      const id = userId;
      dispatch(getUserSelectScreen({ id }));
    }
  }, [dispatch, userId]);
  useEffect(() => {
    if (updateloading === API_STATUS.FULFILLED) {
      dispatch(clearUserLoadingDatas());

      const id = userId;
      dispatch(getUserSelectScreen({ id }));
    }
  }, [updateloading, userId, dispatch]);
  useEffect(() => {
    if (selectScreenData) {
      const actions = {};
      selectScreenData.forEach((screen) => {
        Object.entries(screen.actions).forEach(([key, value]) => {
          actions[key] = value;
        });
      });
      setUserActions(actions);
    }
  }, [selectScreenData]);

  // useEffect(() => {
  //   if (!hasMounted.current) {
  //     hasMounted.current = true;
  //     initMenu();
  //   } else if (Object.keys(userActions).length > 0) {
  //     initMenu();
  //   }
  // }, [userActions]);

  return (
    <>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {template_settings.sidebar ? (
            <SimpleBar style={{ maxHeight: "100%" }}>
              <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                  <li className="menu-title">{"Menu"}</li>

                  <li>
                    <Link to="/dashboard" className="waves-effect">
                      <i className="ri-dashboard-line"></i>
                      {/* <span className="badge rounded-pill bg-success float-end">
                        3
                      </span> */}
                      <span className="ms-1">{"Dashboard"}</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/#"
                      className="has-arrow waves-effect"
                      aria-expanded="true"
                    >
                      <i className="ri-building-3-line"></i>
                      <span className="ms-1">{"Masters"}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/ecommerce-products">{"Users"}</Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Customers"}</Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Vendor"}</Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Company"}</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link
                      to="/#"
                      className="has-arrow waves-effect"
                      aria-expanded="true"
                    >
                      <i className=" ri-shopping-cart-fill"></i>
                      <span className="ms-1">{"Inventory"}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/product_list">{"Products"}</Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Stock"}</Link>
                      </li>
                    </ul>
                  </li>

                  {/* <li>
                    <Link
                      to="/#"
                      className="has-arrow waves-effect"
                      aria-expanded="true"
                    >
                      <i className="ri-store-2-line"></i>
                      <span className="ms-1">{"Purchase"}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/ecommerce-products">{"Purchase Order"}</Link>
                      </li>
                      <li>
                        <Link to="/purchase_invoice">
                          {"Purchase Invoice"}
                        </Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">
                          {"Purchase Return"}
                        </Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Payment"}</Link>
                      </li>
                    </ul>
                  </li> */}

                  <li>
                    <Link
                      to="/#"
                      className="has-arrow waves-effect"
                      aria-expanded="true"
                    >
                      <i className="ri-store-3-line"></i>
                      <span className="ms-1">{"Sales"}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/ecommerce-products">
                          {"Sales Quotation"}
                        </Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Sales Order"}</Link>
                      </li>
                      <li>
                        <Link to="/sales_invoice">{"Sales Invoice"}</Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Sales Return"}</Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Payment"}</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link
                      to="/#"
                      className="has-arrow waves-effect"
                      aria-expanded="true"
                    >
                      <i className=" ri-file-list-3-line"></i>
                      <span className="ms-1">{"Reports"}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/ecommerce-products">{"Sales"}</Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Purchase"}</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </SimpleBar>
          ) : (
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{"Menu"}</li>
                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ri-dashboard-line"></i>
                    {/* <span className="badge rounded-pill bg-success float-end">
                      3
                    </span> */}
                    <span className="ms-1">{"Dashboard"}</span>
                  </Link>
                </li>

                <li>
                  <Link
                    to="/#"
                    className="has-arrow waves-effect"
                    aria-expanded="true"
                  >
                    <i className="ri-building-3-line"></i>
                    <span className="ms-1">{"Masters"}</span>
                  </Link>
                  <ul className="sub-menu">
                    {( roleID==2||hasAction("Users", {
                      add: true,
                      edit: true,
                      delete: true,
                    })) && (
                      <li>
                        <Link to="/userList">{"Users"}</Link>
                      </li>
                    )}
                    {hasAction("Customer", {
                      add: true,
                      edit: true,
                      delete: true,
                    }) && (
                      <li>
                        <Link to="/customerlist">{"Customers"}</Link>
                      </li>
                    )}
                    {/* 
      {hasAction("Vendor", { add: true, edit: true, delete: true }) && (
        <li>
          <Link to="/vendorList">{"Vendor"}</Link>
        </li>
      )}
      */}
                    {hasAction("Company", { add: true }) && (
                      <li>
                        <Link to="/company">{"Company"}</Link>
                      </li>
                    )}
                  </ul>
                </li>

                <li>
                  <Link
                    to="/sales_invoicelist"
                    className="has-arrow waves-effect"
                  >
                    <i className="ri-store-3-line"></i>
                    <span className="ms-1">{"Sales Invoice"}</span>
                  </Link>
                  <ul className="sub-menu">
                    {hasAction("Service Inventory", {
                      add: true,
                      edit: true,
                      delete: true,
                    }) && (
                      <li>
                        <Link to="/product_list" className="waves-effect">
                          {"Inventory"}
                        </Link>
                      </li>
                    )}
                    {hasAction("Sales Invoice", {
                      add: true,
                      edit: true,
                      delete: true,
                    }) && (
                      <li>
                        <Link to="/sales_invoicelist">{"Sales Invoice"}</Link>
                      </li>
                    )}
                    {hasAction("Sales Payment", {
                      act: true,
                    }) && (
                      <li>
                        <Link to="/sales/payment">{"Payment"}</Link>
                      </li>
                    )}
                    {hasAction("Sales Report", {
                      act: true,
                    }) && (
                      <li>
                        <Link to="/reports/sales">{"Report"}</Link>
                      </li>
                    )}
                  </ul>
                </li>

                {/* <li>
                    <Link
                      to="/#"
                      className="has-arrow waves-effect"
                      aria-expanded="true"
                    >
                      <i className="ri-store-2-line"></i>
                      <span className="ms-1">{"Purchase"}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="#">{"Purchase Order"}</Link>
                      </li>
                      <li>
                        <Link to="/purchase_invoice">{"Purchase Invoice"}</Link>
                      </li>
                      <li>
                        <Link to="#">{"Purchase Return"}</Link>
                      </li>
                      <li>
                        <Link to="#">{"Payment"}</Link>
                      </li>
                    </ul>
                  </li> */}
                {/* <li>
                  <Link
                    to="/finance"
                    className="has-arrow waves-effect"
                    aria-expanded="true"
                  >
                    <i class="ri-inbox-archive-line"></i>
                    <span className="ms-1">{"Purchase"}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/purchase_list" className="waves-effect">
                        {" Inventory"}
                      </Link>
                    </li>
                    <li>
                      <Link to="/purchase/vendor">{"Vendor"}</Link>
                    </li>
                    <li>
                      <Link to="/purchase_invoice">{"Purchase Invoice"}</Link>
                    </li>
                    <li>
                      <Link to="/purchase/report">{"Report"}</Link>
                    </li>
                    <li>
                      <Link to="/purchase/order">{"Purchase Order"}</Link>
                    </li>

                    <li>
                      <Link to="/vendor_payment">{"Payment"}</Link>
                    </li>
                  </ul>
                </li> */}

                <li>
                  <Link
                    to="/finance"
                    className="has-arrow waves-effect"
                    aria-expanded="true"
                  >
                    <i className="ri-inbox-archive-line"></i>
                    <span className="ms-1">{"Purchase"}</span>
                  </Link>
                  <ul className="sub-menu">
                    {hasAction("Purchase Inventry", {
                      add: true,
                      edit: true,
                      delete: true,
                    }) && (
                      <li>
                        <Link to="/purchase_list" className="waves-effect">
                          {"Inventory"}
                        </Link>
                      </li>
                    )}

                    {hasAction("Vendor", {
                      add: true,
                      edit: true,
                      delete: true,
                    }) && (
                      <li>
                        <Link to="/purchase/vendor">{"Vendor"}</Link>
                      </li>
                    )}

                    {hasAction("Purchase Invoice", {
                      add: true,
                      edit: true,
                      delete: true,
                    }) && (
                      <li>
                        <Link to="/purchase_invoice">{"Purchase Invoice"}</Link>
                      </li>
                    )}

                    {hasAction("Purchase Report", {
                      act: true,
                    }) && (
                      <li>
                        <Link to="/vendor_payment">{"Payment"}</Link>
                      </li>
                    )}
                    {hasAction("Purchase Report", {
                      act: true,
                    }) && (
                      <li>
                        <Link to="/purchase/report">{"Report"}</Link>
                      </li>
                    )}
                  </ul>
                </li>

                {/* <li>
                  <Link
                    to="/#"
                    className="has-arrow waves-effect"
                    aria-expanded="true"
                  >
                    <i className="ri-store-2-line"></i>
                    <span className="ms-1">{"Purchase"}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="#">{"Purchase Order"}</Link>
                    </li>
                    <li>
                      <Link to="/purchase_invoice">{"Purchase Invoice"}</Link>
                    </li>
                    <li>
                      <Link to="#">{"Purchase Return"}</Link>
                    </li>
                    <li>
                      <Link to="#">{"Payment"}</Link>
                    </li>
                  </ul>
                </li> */}

                {/**/}
                {/* <li>
                  <Link
                    to="/sales_invoicelist"
                    className="waves-effect"
                  >
                    <i className="ri-store-3-line"></i>
                    <span className="ms-1">{"Sales Invoice"}</span>
                  </Link>
                  {/* <ul className="sub-menu"> */}
                {/* <li>
                      <Link to="/sales">{"Sales Quotation"}</Link>
                    </li>
                    <li>
                      <Link to="#">{"Sales Order"}</Link>
                    </li> */}
                {/* <li>
                      <Link to="/sales_invoicelist">{"Sales Invoice"}</Link>
                    </li> */}
                {/* <li>
                      <Link to="#">{"Sales Return"}</Link>
                    </li>
                    <li>
                      <Link to="/sales/payment">{"Payment"}</Link>
                    </li> */}
                {/* </ul> */}
                {/* </li> */}
                {/* <li>
                  <Link
                    to="/#"
                    className="has-arrow waves-effect"
                    aria-expanded="true"
                  >
                    <i className=" ri-file-list-3-line"></i>
                    <span className="ms-1">{"Reports"}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/reports/sales">{"Sales"}</Link>
                    </li>
                    <li>
                      <Link to="/reports/purchase">{"Purchase"}</Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default Sidebar;
